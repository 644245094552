import { LeasingActions as Actions } from "./leasing.action.keys";

import { LeaseContractType } from "../../../__generated__/graphql-types";
import { LeaseOffersQuery_search_Search_advert_Advert_leasingOffers_LeaseTerms } from "../../../connected-components/hoc/query/__generated__/pdp-lease-offers.query";
import { ProductPageQuery_search_Search_advert_Advert } from "../../../connected-components/hoc/query/__generated__/pdp-page-data.query";
import {
  LeasingContextAction,
  LeasingModalState,
  ProductPageStockFilters,
} from "../types/leasing.types";

export const setLeasingContractType = (
  payload: LeaseContractType,
): LeasingContextAction => ({
  type: Actions.SET_LEASING_CONTRACT_TYPE,
  payload,
});

export const setSelectedLeaseOffer = (
  payload: LeaseOffersQuery_search_Search_advert_Advert_leasingOffers_LeaseTerms,
): LeasingContextAction => ({
  type: Actions.SET_SELECTED_LEASE_OFFER,
  payload,
});

export const setSelectedStockFilter = (
  payload: ProductPageStockFilters,
): LeasingContextAction => ({
  type: Actions.SET_SELECTED_STOCK_FILTER,
  payload,
});

export const setSelectedBusinessType = (
  payload: string,
): LeasingContextAction => ({
  type: Actions.SET_SELECTED_BUSINESS_TYPE,
  payload,
});

export const setProductData = (
  payload: ProductPageQuery_search_Search_advert_Advert,
): LeasingContextAction => ({
  type: Actions.SET_PRODUCT_DATA,
  payload,
});

export const setLeaseOffersData = (
  payload: LeaseOffersQuery_search_Search_advert_Advert_leasingOffers_LeaseTerms[],
): LeasingContextAction => ({
  type: Actions.SET_LEASE_OFFERS_DATA,
  payload,
});

export const setLeasingModal = (
  payload: LeasingModalState,
): LeasingContextAction => ({
  type: Actions.SET_LEASING_MODAL,
  payload,
});

export const setCloseLeasingModal = (): LeasingContextAction => ({
  type: Actions.SET_CLOSE_LEASING_MODAL,
});

export const setLeaseOption = (payload: string): LeasingContextAction => ({
  type: Actions.SET_LEASE_OPTION,
  payload,
});

export const setBusinessTypeError = (
  payload: boolean,
): LeasingContextAction => ({
  type: Actions.SET_BUSINESS_TYPE_ERROR,
  payload,
});

export const removeSelectedLeaseOffer = (): LeasingContextAction => ({
  type: Actions.REMOVE_SELECTED_LEASE_OFFER,
});
