import { useParams } from "react-router-dom";

import {
  constructLeaseOption,
  sortLeaseOffersByPriceAndDelivery,
} from "@at/leasing-utils";
import { useQuery } from "@at/sauron-platform-graphql";

import { LeaseContractType } from "../../../../../__generated__/graphql-types";
import {
  LeaseOffersQuery,
  LeaseOffersQueryVariables,
  LeaseOffersQuery_search_Search_advert_Advert_leasingOffers_LeaseTerms,
} from "../../../../../connected-components/hoc/query/__generated__/pdp-lease-offers.query";
import {
  ProductPageQuery,
  ProductPageQueryVariables,
} from "../../../../../connected-components/hoc/query/__generated__/pdp-page-data.query";
import { leaseOffersQuery } from "../../../../../connected-components/hoc/query/pdp-lease-offers.query";
import productPageQuery from "../../../../../connected-components/hoc/query/pdp-page-data.query";
import {
  setProductData,
  setSelectedLeaseOffer,
  setSelectedStockFilter,
  setLeaseOption,
  setLeaseOffersData,
  removeSelectedLeaseOffer,
} from "../../../../../contexts/leasing/actions/leasing.actions";
import {
  useLeasingStateContext,
  useLeasingDispatchContext,
} from "../../../../../contexts/leasing/context/leasing.context";
import {
  LeasingState,
  ProductPageStockFilters,
} from "../../../../../contexts/leasing/types/leasing.types";
import { ProductPageVariables } from "../../../../../pages/leasing/leasing-product-page-content.types";

import { getFilteredLeaseOffersByStock } from "../../../../../utils/leasing/getFilteredLeaseOffersByStock/get-filtered-lease-offers-by-stock";
import { getIsOfferInStock } from "../../../../../utils/leasing/getIsOfferInStock/get-is-offer-in-stock";

export const handleLeaseOfferData = (
  leasingOffers: readonly (LeaseOffersQuery_search_Search_advert_Advert_leasingOffers_LeaseTerms | null)[],
  state: Partial<LeasingState>,
) => {
  const {
    selectedStockFilter,
    selectedLeaseOffer,
    leaseContractType = LeaseContractType.personal,
  } = state;
  /**
   * Filter out null/undefined
   */
  const filteredLeaseOffers = leasingOffers?.filter(
    Boolean,
  ) as LeaseOffersQuery_search_Search_advert_Advert_leasingOffers_LeaseTerms[];

  const { depositMonths, termMonths, totalMileage } =
    filteredLeaseOffers[0] || {};

  /**
   * Construct lease option based off the first lease offer
   * This is required for "cheapest" leaseOffer queries
   */
  const updatedLeaseOption = constructLeaseOption({
    depositMultiple: depositMonths,
    numberOfMonths: termMonths,
    mileage: totalMileage,
  });

  /**
   * Check if In Stock offers are available based off the delivery estimates
   */
  const hasInStockOffers = Boolean(
    filteredLeaseOffers?.some((offer) =>
      offer?.deliveryEstimate
        ? getIsOfferInStock(offer.deliveryEstimate)
        : false,
    ),
  );

  /**
   * 1. Check if In Stock offers are available
   * 3. If selectedStockFilter is All, keep the same value
   * 2. If In Stock offers are available, and selectedStockFilter is also In Stock, keep the same value
   * 4. If In Stock offers are not available, and selectedStockFilter is In Stock, change to All
   */
  const stockFilter =
    selectedStockFilter === ProductPageStockFilters.IN_STOCK && hasInStockOffers
      ? selectedStockFilter
      : ProductPageStockFilters.ALL;

  /**
   * Filter available lease offers based off the stockFilter and offer delivery estimates
   */
  const filteredLeaseOffersByStock = getFilteredLeaseOffersByStock(
    filteredLeaseOffers,
    stockFilter,
  );

  const sortedAndFilteredLeaseOffersByStock = sortLeaseOffersByPriceAndDelivery(
    filteredLeaseOffersByStock,
    leaseContractType || LeaseContractType.personal,
  );

  /**
   * 1. Find the same available lease offer based off the selectedLeaseOffer
   * 2. If selectedLeaseOffer is undefined, or the selectedOffer doesn't match any lease offers, get the cheapest offer with a preference for In Stock offers
   */
  const updatedSelectedLeaseOffer =
    sortedAndFilteredLeaseOffersByStock.find(
      (offer) =>
        selectedLeaseOffer?.colour === offer.colour &&
        selectedLeaseOffer?.upholstery === offer.upholstery,
    ) ||
    sortedAndFilteredLeaseOffersByStock.find(
      (offer) => selectedLeaseOffer?.colour === offer.colour,
    ) ||
    sortedAndFilteredLeaseOffersByStock[0];

  return {
    updatedLeaseOption,
    filteredLeaseOffers,
    hasInStockOffers,
    stockFilter,
    filteredLeaseOffersByStock,
    updatedSelectedLeaseOffer,
  };
};

export const useLeasingQueries = (leasingState?: LeasingState) => {
  const { advertId } = useParams<ProductPageVariables>();
  const dispatch = useLeasingDispatchContext();
  const stateFromContext = useLeasingStateContext();

  const state = leasingState || stateFromContext;

  const { leaseOption, productData, leaseContractType } = state;

  const {
    data: productPageData,
    loading: productPageLoading,
    error: productPageError,
  } = useQuery<ProductPageQuery, ProductPageQueryVariables>(productPageQuery, {
    errorPolicy: "all",
    variables: {
      advertId: advertId,
    },
    onCompleted: (data) => {
      if (data) {
        dispatch(setProductData(data?.search.advert));
      }
    },
    skip: !!productData,
  });

  const {
    data: leaseOffersData,
    loading: leaseOffersLoading,
    error: leaseOffersError,
  } = useQuery<LeaseOffersQuery, LeaseOffersQueryVariables>(leaseOffersQuery, {
    errorPolicy: "all",
    variables: {
      advertId,
      leaseOption,
      leaseContractType,
    },
    onCompleted: (data) => {
      if (data?.search.advert.leasingOffers?.[0]) {
        const {
          updatedLeaseOption,
          filteredLeaseOffers,
          stockFilter,
          updatedSelectedLeaseOffer,
        } = handleLeaseOfferData(data?.search.advert.leasingOffers, state);

        /**
         * Update the context with the new selected lease offer, stock filter, lease option, and lease offers data
         */
        dispatch(setSelectedLeaseOffer(updatedSelectedLeaseOffer));
        dispatch(setSelectedStockFilter(stockFilter));
        dispatch(setLeaseOption(updatedLeaseOption));
        dispatch(setLeaseOffersData(filteredLeaseOffers));
      } else {
        dispatch(removeSelectedLeaseOffer());
        dispatch(setLeaseOffersData([]));
      }
    },
    skip: !advertId || !leaseOption,
  });

  return {
    productPageData,
    productPageLoading,
    productPageError,
    leaseOffersData,
    leaseOffersLoading,
    leaseOffersError,
  };
};
