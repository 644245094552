export enum LeasingActions {
  SET_ALL_LEASE_STATE = "SET_ALL_LEASE_STATE",
  SET_LEASING_CONTRACT_TYPE = "SET_LEASING_CONTRACT_TYPE",
  SET_SELECTED_STOCK_FILTER = "SET_SELECTED_STOCK_FILTER",
  SET_SELECTED_LEASE_OFFER = "SET_SELECTED_LEASE_OFFER",
  SET_SELECTED_BUSINESS_TYPE = "SET_SELECTED_BUSINESS_TYPE",
  SET_PRODUCT_DATA = "SET_PRODUCT_DATA",
  SET_LEASE_OFFERS_DATA = "SET_LEASE_OFFERS_DATA",
  SET_LEASE_OPTION = "SET_LEASE_OPTION",
  SET_LEASING_MODAL = "SET_LEASING_MODAL",
  SET_CLOSE_LEASING_MODAL = "SET_CLOSE_LEASING_MODAL",
  SET_BUSINESS_TYPE_ERROR = "SET_BUSINESS_TYPE_ERROR",
  REMOVE_SELECTED_LEASE_OFFER = "REMOVE_SELECTED_LEASE_OFFER",
}
